 <template>
  <span>
  
        <Button style="margin: 5px" type="info" @click="showAPIreadme"
          >API地址代理对照表</Button
        >
        <div v-if="tips">
          API地址代理转换：对于平台内的API地址，将域名段转换为代理编码，可以满足各种环境的应用（测试、生产）。
        </div>
     
  </span>
</template>

<script>
/**
 * import APIreadme from "./APIreadme";
 */
export default {
    props: {
      tips:{ type: Boolean, default: true },
    },
  created() {},
  methods: {
    showAPIreadme() {
      let proxy = require("./proxy.js").default;
      // console.log(proxy);
      let str = '<div style="width:100%;height:500px;overflow:auto;">';
          str += '<div style="width:100%;margin:10px 0;color:#cc0000;">可以通过“Ctrl+F”查找域名前半段地址，找到对应的代理地址。</div>';
          str += '<div style="width:100%;margin:10px 0;color:blue;">例：https://api.gateway.prod.liefengtech.com/apps/oldpeople/api/tvbox/notice/v2/getAffichePageList</div>';
          str += '<div style="width:100%;margin:10px 0;color:blue;">通过查找：https://api.gateway.prod.liefengtech.com/apps/oldpeople</div>';
          str += '<div style="width:100%;margin:10px 0;color:blue;">代理地址为：/oldpeople</div>';
          str += '<div style="width:100%;margin:10px 0;color:blue;">转换后地址为：/oldpeople/api/tvbox/notice/v2/getAffichePageList</div>';
      for (let key in proxy) {
        str +=
          '<div style="width:100%;margin-top:20px;">代理地址：' +
          key +
          '</div><div style="margin-left:20px">生产地址：' +
          proxy[key].prod +
          '</div><div style="margin-left:20px">测试地址：' +
          proxy[key].test +
          "</div>";
      }
      str += "</div>";
      this.$Modal.info({
        title: "API地址代理对照表",
        content: str,
        width:"900px",
        scrollable: true,
      });
    },
   
  },
};
</script>
