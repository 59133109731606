 <template>
    <!-- 样式组件 -->
    <div
        :class="'liefeng-banner-view' + (shadow || data.shadow ? ' liefeng-banner-shadow' : '') + (radius || data.radius ? ' liefeng-banner-radius' : '')"
        :style="'height:' + (data.height || height) * proportion + 'px;width:' + (data.width || width) * proportion + 'px'"
        v-if="!isParamComp"
    ></div>
    <!-- 参数设置组件 -->
    <div class="liefeng-banner-params" v-else>
        <Form :label-width="100" ref="bannerForm">
            <FormItem label="宽度">
                <Input type="Number" v-model.number="data.width" placeholder="Banner宽度，默认：500" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="高度">
                <Input type="Number" v-model.number="data.height" placeholder="Banner高度，默认：320" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="自动播放">
                <RadioGroup v-model="data.autoplay">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="时间间隔">
                <Input type="Number" v-model.number="data.interval" placeholder="自动切换时间间隔，默认：5000"></Input>
            </FormItem>
            <FormItem label="阴影">
                <RadioGroup v-model="data.shadow" @on-change="handleData">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="圆角">
                <RadioGroup v-model="data.radius" @on-change="handleData">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="面板指示点">
                <RadioGroup v-model="data.indicatorDots">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="两边铺满">
                <RadioGroup v-model="data.full">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="左边距">
                <Input type="Number" v-model.trim="data.marginLeft" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="右边距">
                <Input type="Number" v-model.trim="data.marginRight" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="上边距">
                <Input type="Number" v-model.trim="data.marginTop" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="下边距">
                <Input type="Number" v-model.trim="data.marginBottom" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="API地址">
                <Input v-model.trim="data.api" placeholder="api优先于固定JSON数据" type="textarea" :autosize="true"></Input>
                <div style="color: blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
            </FormItem>
            <FormItem label="响应参数互换" v-if="data.api">
                <Input
                    v-model.trim="data.convertParams"
                    type="textarea"
                    rows="10"
                    placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
                ></Input>
                <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')">格式化 / 验证JSON合法性</Button>
                <APIreadme />
            </FormItem>
            <FormItem label="JSON数据" v-else>
                <Input
                    v-model.trim="data.data"
                    type="textarea"
                    rows="15"
                    placeholder='例：[
                {
                  "image": "图片地址",
                  "url": "跳转地址（可选）",
                  "title": "名称（可选）",
                  "video": "视频地址（可选）"
                }
              ]'
                ></Input>
                <Button style="margin: 5px" type="success" @click="verifyJson('data')">格式化 / 验证JSON合法性</Button>
                <Button style="margin: 5px" type="info" @click="importDfData">导入例子</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
/**
 * import Banner from "./components/Banner";
 * 通用的Banner广告位
 */
import APIreadme from "./APIreadme"
export default {
    components: { APIreadme },
    props: {
        isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
        height: { type: Number, default: 500 }, //高度，单位：px
        width: { type: Number, default: 500 }, //高度，单位：px
        shadow: { type: Boolean, default: false }, //带阴影
        radius: { type: Boolean, default: false }, //圆角
        data: {
            //组件数据，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        target: {
            //组件对象，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        proportion: {
            type: Number,
            default: () => {
                return 0
            },
        },
    },
    data() {
        return {
            swiperBanner: "Banner" + this.$core.createID(),
            initData: {
                //初始化用的数据，每个组件需要具备
                type: "Banner",
                api: "",
                data: null,
                height: 500,
                width: 500,
                shadow: false,
                radius: false,
                indicatorDots: false,
                autoplay: false,
                interval: 5000,
                marginLeft: "0",
                marginRight: "0",
                marginTop: "0",
                marginBottom: "0",
            },
            dfData: JSON.stringify(
                [
                    {
                        image: "./apps/suiyue/images/remote/banner_03.png",
                        url: "",
                        title: "",
                    },
                    {
                        image: "./apps/suiyue/images/remote/banner_04.png",
                        url: "",
                        title: "",
                    },
                ],
                null,
                "\t"
            ),
        }
    },
    mounted() {
        this.handleData()
    },
    methods: {
        importDfData() {
            if (this.data.data) {
                this.$Modal.confirm({
                    title: "提示",
                    content: "编辑框已存在数据，是否覆盖？",
                    onOk: res => {
                        this.data.data = this.dfData
                    },
                })
            } else {
                this.data.data = this.dfData
            }
        },
        handleData() {
            this.$(this.target).css("height", (this.data.height || 320) * this.proportion + "px")
            this.$(this.target).css("width", (this.data.width || 500) * this.proportion + "px")
            if (this.data.shadow) this.$(this.target).addClass("liefeng-banner-shadow")
            else this.$(this.target).removeClass("liefeng-banner-shadow")
            if (this.data.radius) this.$(this.target).addClass("liefeng-banner-radius")
            else this.$(this.target).removeClass("liefeng-banner-radius")

            var that = this.$(this.target)
            that.empty()
            that.css("marginLeft", this.data.marginLeft * this.proportion)
            that.css("marginRight", this.data.marginRight * this.proportion)
            that.css("marginTop", this.data.marginTop * this.proportion)
            that.css("marginBottom", this.data.marginBottom * this.proportion)
        },
        verifyJson(target) {
            //验证JSON,每个组件需要具备
            try {
                if (!this.data[target]) {
                    this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" })
                } else {
                    var json = JSON.parse(this.data[target])
                    this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" })
                    this.data[target] = JSON.stringify(json, null, "\t")
                }
            } catch (e) {
                this.$Modal.error({
                    title: "验证结果",
                    content: "JSON数据有误，请检查。",
                })
            }
        },
    },
}
</script>
<style lang="less">
.liefeng-banner-view {
    width: 100%;
    margin: 5px 0;
    background-color: #eee;
    background-image: url("/images/banner_03.png");
    background-size: 100% auto;
    background-position: top;
}
.liefeng-banner-params {
    width: 100%;
    margin: 5px 0;
}
.liefeng-banner-shadow {
    box-shadow: 0px 3px 10px rgba(58, 60, 62, 0.3);
}
.liefeng-banner-radius {
    border-radius: 8px;
}
</style>